
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState, useMemo } from 'react';
import { encodePassphrase, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';
import { Button, Modal, Input, Switch, Checkbox, notification, Dropdown, Radio } from 'antd';
import type { MenuProps } from 'antd';
import useTranslation from 'next-translate/useTranslation';
import setLanguage from 'next-translate/setLanguage';
interface TabsProps {
    children: ReactElement[];
    selectedIndex?: number;
    onTabSelected?: (index: number) => void;
}
const { TextArea } = Input;
function Tabs(props: TabsProps) {
    const activeIndex = props.selectedIndex ?? 0;
    if (!props.children) {
        return <></>;
    }
    let tabs = React.Children.map(props.children, (child, index) => {
        return (<button className="lk-button" onClick={() => {
                if (props.onTabSelected)
                    props.onTabSelected(index);
            }} aria-pressed={activeIndex === index}>
        {child?.props.label}
      </button>);
    });
    return (<div className={styles.tabContainer}>
      <div className={styles.tabSelect}>{tabs}</div>
      {props.children[activeIndex]}
    </div>);
}
function ConnectionTab_gpt({ label }: {
    label: string;
}) {
    const router = useRouter();
    const [showContent, setShowContent] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [GptsId, setGptsId] = useState('');
    const [isGptsIdValid, setIsGptsIdValid] = useState(true);
    const { t } = useTranslation('home');
    const { locale } = router;
    const [videoChat, setVideoChat] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const handleChange = (e: any) => {
        const { value } = e.target;
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}|:"<>?~`,./;'[\]\\-]*$/;
        if (regex.test(value) || value === '') {
            setGptsId(value);
        }
    };
    const menu: MenuProps['items'] = [
        {
            label: 'English',
            key: 'en',
            disabled: locale === 'en'
        },
        {
            label: '简体中文',
            key: 'zh-CN',
            disabled: locale === 'zh-CN'
        },
        {
            label: '繁體中文',
            key: 'zh-TW',
            disabled: locale === 'zh-TW'
        }
    ];
    const handleMenuClick: MenuProps['onClick'] = async (e) => {
        await setLanguage(e.key);
    };
    const menuProps = {
        items: menu,
        onClick: handleMenuClick,
    };
    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        setLoading(true);
        event.preventDefault();
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            const formData = new FormData(event.target as HTMLFormElement);
            const chat = showContent;
            const key = formData.get('key');
            const model = formData.get('model');
            const voice = formData.get('voice');
            const myHeaders = new Headers();
            myHeaders.append("authorization", `Bearer ${key}`);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "chat": chat,
                "model": model,
                "voice": voice,
                "video": videoChat,
                ...((checked && !GptsId && !videoChat) && { "instructions": value }),
                ...((checked && GptsId && !videoChat) && { "GPTS_ID": GptsId })
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow" as RequestRedirect
            };
            fetch("https://endpoints.gpt.biz/v2/voice/get_token", requestOptions)
                .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        api.error({
                            message: t("notification_error_text"),
                            description: t("notification_ai_notFound"),
                            placement: "top",
                        });
                    }
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
                .then((result) => {
                const data = JSON.parse(result);
                setLoading(false);
                let path = null;
                if (data?.chat) {
                    path = `/room/?liveKitUrl=${data.url}&token=${data.token}&session_id=${data.session_id}&api_key=${key}&id=${data.chat.id}&conversation_id=${data.chat.conversation_id}&key=${key}#${encodePassphrase(data.e2ee_key)}`;
                }
                else {
                    path = `/room/?liveKitUrl=${data.url}&token=${data.token}&api_key=${key}&session_id=${data.session_id}#${encodePassphrase(data.e2ee_key)}`;
                }
                sessionStorage.setItem("systemPrompt", JSON.stringify({
                    checked,
                    GptsId,
                    instructions: value
                }));
                router.push(path);
            })
                .catch((error) => {
                setLoading(false);
            });
        }).catch(error => {
            if (error.name === 'NotFoundError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_notMicDevice"),
                    placement: "top",
                });
            }
            else if (error.name === 'NotAllowedError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_notMicPermission"),
                    placement: "top",
                });
            }
            else if (error.name === 'NotReadableError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_deviceBeUsed"),
                    placement: "top",
                });
            }
            else {
                api.error({
                    message: t("notification_error_text"),
                    description: `${t("notification_expectError")}: ${error}`,
                    placement: "top",
                });
            }
        });
        // if (e2ee) {
        //   router.push(
        //     `/custom/?liveKitUrl=${serverUrl}&token=${token}#${encodePassphrase(sharedPassphrase)}`,
        //   );
        // } else {
        //   router.push(`/custom/?liveKitUrl=${serverUrl}&token=${token}`);
        // }
    };
    useEffect(() => {
        const systemPrompt = sessionStorage.getItem("systemPrompt");
        if (systemPrompt) {
            const data = JSON.parse(systemPrompt);
            setChecked(data.checked);
            setGptsId(data.GptsId);
            setValue(data.instructions);
        }
    }, []);
    useEffect(() => {
        if (GptsId && checked && value) {
            setIsGptsIdValid(false);
        }
        else {
            setIsGptsIdValid(true);
        }
        const handleBeforeUnload = (event: any) => {
            sessionStorage.setItem("systemPrompt", JSON.stringify({
                checked,
                GptsId,
                instructions: value
            }));
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [GptsId, value, checked]);
    return (<>
    {contextHolder}
    <form className={styles.tabContent} onSubmit={onSubmit} style={{ margin: '8px', maxWidth: '400px', width: '100%' }}>
      <p style={{ marginTop: 0 }}>
        {t('tips')}
        <a href='https://platform.gpt.biz' target='_blank'>{t('link_text')}</a>
      </p>
      <input id="key" name="key" maxLength={100} placeholder={t('input_key_placeholder')} required style={{ padding: '4px', fontSize: 'inherit', lineHeight: 'inherit', height: 50 }}/>
      <select name="model" style={{ padding: '4px', fontSize: 'inherit', lineHeight: 'inherit', height: 50 }} required>
        <option value="">{t('options_model_text')}</option>
        {!checked && (<option value="text-davinci-002-render-sha">text-davinci-002-render-sha</option>)}
        <option value="gpt-4">gpt-4</option>
        <option value="gpt-4o">gpt-4o</option>
      </select>
      <select name="voice" style={{ padding: '4px', fontSize: 'inherit', lineHeight: 'inherit', height: 50 }} required>
        <option value="">{t('options_voice_text')}</option>
        <option value="juniper">Juniper</option>
        <option value="ember">Ember</option>
        <option value="cove">Cove</option>
        <option value="breeze">Breeze</option>
      </select>
      <div>
        <Checkbox defaultChecked={showContent} onChange={(e) => setShowContent(e.target.checked)} style={{ color: '#fff' }}>{t('checkbox_showContent_text')}</Checkbox>
      </div>
      <div>
        <Checkbox defaultChecked={videoChat} onChange={(e) => setVideoChat(e.target.checked)} style={{ color: '#fff' }}>{t('checkbox_videoChat_text')}</Checkbox>
      </div>
      {(checked && videoChat) && (<p style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>{t('input_gpts_warning')}</p>)}
      <hr style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}/>
      <Button style={{ paddingInline: '1.25rem', width: '100%', backgroundColor: '#1677ff' }} className="lk-button" htmlType="submit" loading={loading}>
        {t('btn_link_text')}
      </Button>
    </form>
    <div style={{ display: 'flex' }}>
      <Button type="primary" onClick={showModal} style={{ margin: "0px 2px" }}>
        {t('btn_systemPrompt_text')}
      </Button>
      <Dropdown menu={menuProps}>
        <Button type="primary" style={{ margin: "0px 2px" }}>
          EN/中
        </Button>
      </Dropdown>
    </div>
    <Modal title={t('modal_title')} open={open} onOk={handleOk} onCancel={handleCancel} centered closable={false} key="modal" footer={[
            <Switch key="switch" checkedChildren={t('modal_switch_open')} unCheckedChildren={t('modal_switch_close')} defaultChecked style={{ margin: '0 8px' }} checked={checked} onChange={(boolean) => {
                    setChecked(boolean);
                    (boolean && GptsId) ? setIsGptsIdValid(false) : setIsGptsIdValid(true);
                }}/>,
            <Button key="back" onClick={handleCancel}>
          {t('modal_btn_cancel')}
        </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
          {t('modal_btn_confirm')}
        </Button>
        ]}>
      <TextArea key="text" value={value} onChange={(e) => setValue(e.target.value)} placeholder={t('modal_placeholder')} autoSize={{ minRows: 3, maxRows: 9 }}/>
      <p>{t("input_gpts_placeholder")}:</p>
      <Input value={GptsId} onChange={handleChange} maxLength={30} status={!isGptsIdValid ? "error" : ""} style={{
            padding: '4px',
        }}/>
      {!isGptsIdValid && (<p style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>{t('input_gpts_warning')}</p>)}
    </Modal>
  </>);
}
function ConnectionTab_neuwork({ label }: {
    label: string;
}) {
    const router = useRouter();
    const [voice, setVoice] = useState("male");
    const [showContent, setShowContent] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState('');
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [GptsId, setGptsId] = useState('');
    const [isGptsIdValid, setIsGptsIdValid] = useState(true);
    const { t } = useTranslation('home');
    const { locale } = router;
    const [videoChat, setVideoChat] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };
    const handleChange = (e: any) => {
        const { value } = e.target;
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+{}|:"<>?~`,./;'[\]\\-]*$/;
        if (regex.test(value) || value === '') {
            setGptsId(value);
        }
    };
    const menu: MenuProps['items'] = [
        {
            label: 'English',
            key: 'en',
            disabled: locale === 'en'
        },
        {
            label: '简体中文',
            key: 'zh-CN',
            disabled: locale === 'zh-CN'
        },
        {
            label: '繁體中文',
            key: 'zh-TW',
            disabled: locale === 'zh-TW'
        }
    ];
    const handleMenuClick: MenuProps['onClick'] = async (e) => {
        await setLanguage(e.key);
    };
    const menuProps = {
        items: menu,
        onClick: handleMenuClick,
    };
    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
        setLoading(true);
        event.preventDefault();
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
            // stream.getTracks().forEach(track => track.stop());
            const key = "GB-LNWEg577HlaPisGHdC5b2MZs9OTrzJQFGsvfhOUmt5SBdjpxwca8sOl0bscouJBB";
            const voiceType = voice;
            const chat = showContent;
            const myHeaders = new Headers();
            myHeaders.append("authorization", `Bearer ${key}`);
            myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
                "chat": chat,
                "voice": voiceType,
                "video": videoChat,
                ...((checked && !GptsId && !videoChat) && { "instructions": value }),
                ...((checked && GptsId && !videoChat) && { "GPTS_ID": GptsId })
            });
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow" as RequestRedirect
            };
            fetch("https://endpoints.gpt.biz/v2/voice/get_token", requestOptions)
                .then((response) => {
                if (!response.ok) {
                    if (response.status === 404) {
                        api.error({
                            message: t("notification_error_text"),
                            description: t("notification_ai_notFound"),
                            placement: "top",
                        });
                    }
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
                .then((result) => {
                const data = JSON.parse(result);
                setLoading(false);
                let path = null;
                if (data?.chat) {
                    path = `/room/?liveKitUrl=${data.url}&token=${data.token}&session_id=${data.session_id}&api_key=${key}&id=${data.chat.id}&conversation_id=${data.chat.conversation_id}#${encodePassphrase(data.e2ee_key)}`;
                }
                else {
                    path = `/room/?liveKitUrl=${data.url}&token=${data.token}&session_id=${data.session_id}&api_key=${key}#${encodePassphrase(data.e2ee_key)}`;
                }
                sessionStorage.setItem("systemPrompt", JSON.stringify({
                    checked,
                    GptsId,
                    instructions: value
                }));
                router.push(path);
            })
                .catch((error) => {
                setLoading(false);
                console.error(error);
            });
        }).catch(error => {
            if (error.name === 'NotFoundError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_notMicDevice"),
                    placement: "top",
                });
            }
            else if (error.name === 'NotAllowedError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_notMicPermission"),
                    placement: "top",
                });
            }
            else if (error.name === 'NotReadableError') {
                api.error({
                    message: t("notification_error_text"),
                    description: t("notification_deviceBeUsed"),
                    placement: "top",
                });
            }
            else {
                api.error({
                    message: t("notification_error_text"),
                    description: `${t("notification_expectError")}: ${error}`,
                    placement: "top",
                });
            }
        });
    };
    useEffect(() => {
        const systemPrompt = sessionStorage.getItem("systemPrompt");
        if (systemPrompt) {
            const data = JSON.parse(systemPrompt);
            setChecked(data.checked);
            setGptsId(data.GptsId);
            setValue(data.instructions);
        }
    }, []);
    useEffect(() => {
        if (GptsId && checked && value) {
            setIsGptsIdValid(false);
        }
        else {
            setIsGptsIdValid(true);
        }
        const handleBeforeUnload = (event: any) => {
            sessionStorage.setItem("systemPrompt", JSON.stringify({
                checked,
                GptsId,
                instructions: value
            }));
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [GptsId, value, checked]);
    return (<>
    {contextHolder}
    <form className={styles.tabContent} onSubmit={onSubmit} style={{ margin: '8px', maxWidth: '400px', width: '100%' }}>
      <div>
        <span>{t("checkbox_voice_text")}：</span>
        <Radio.Group onChange={(e) => setVoice(e.target.value)} value={voice}>
          <Radio value={"male"} style={{ color: '#fff' }}>{t("checkbox_voice_male")}</Radio>
          <Radio value={"female"} style={{ color: '#fff' }}>{t("checkbox_voice_female")}</Radio>
        </Radio.Group>
      </div>
      <div>
        <Checkbox defaultChecked={showContent} onChange={(e) => setShowContent(e.target.checked)} style={{ color: '#fff' }}>{t('checkbox_showContent_text')}</Checkbox>
      </div>
      <div>
        <Checkbox defaultChecked={videoChat} onChange={(e) => setVideoChat(e.target.checked)} style={{ color: '#fff' }}>{t('checkbox_videoChat_text')}</Checkbox>
      </div>
      {(checked && videoChat) && (<p style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>{t('input_gpts_warning')}</p>)}
      <hr style={{ width: '100%', borderColor: 'rgba(255, 255, 255, 0.15)', marginBlock: '1rem' }}/>
      <Button style={{ paddingInline: '1.25rem', width: '100%', backgroundColor: '#1677ff' }} className="lk-button" htmlType="submit" loading={loading}>
        {t('btn_link_text')}
      </Button>
    </form>
    <div style={{ display: 'flex' }}>
      <Button type="primary" onClick={showModal} style={{ margin: "0px 2px" }}>
        {t('btn_systemPrompt_text')}
      </Button>
      <Dropdown menu={menuProps}>
        <Button type="primary" style={{ margin: "0px 2px" }}>
          EN/中
        </Button>
      </Dropdown>
    </div>
    <Modal title={t('modal_title')} open={open} onOk={handleOk} onCancel={handleCancel} centered closable={false} footer={[
            <Switch checkedChildren={t('modal_switch_open')} unCheckedChildren={t('modal_switch_close')} defaultChecked style={{ margin: '0 8px' }} checked={checked} onChange={(boolean) => {
                    setChecked(boolean);
                    (boolean && GptsId) ? setIsGptsIdValid(false) : setIsGptsIdValid(true);
                }}/>,
            <Button key="back" onClick={handleCancel}>
          {t('modal_btn_cancel')}
        </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
          {t('modal_btn_confirm')}
        </Button>
        ]}>
      <TextArea value={value} onChange={(e) => setValue(e.target.value)} placeholder={t('modal_placeholder')} autoSize={{ minRows: 3, maxRows: 9 }}/>
      <p>{t("input_gpts_placeholder_nw")}:</p>
      <Input value={GptsId} onChange={handleChange} maxLength={30} status={!isGptsIdValid ? "error" : ""} style={{
            padding: '4px',
        }}/>
      {!isGptsIdValid && (<p style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>{t('input_gpts_warning')}</p>)}
    </Modal>
  </>);
}
const getServerSideProps: GetServerSideProps<{
    tabIndex: number;
    query: any;
}> = async ({ query, res, }) => {
    res.setHeader('Cache-Control', 'public, max-age=7200');
    const tabIndex = query.tab === 'custom' ? 1 : 0;
    return { props: { tabIndex, query } };
};
const Home = ({ tabIndex, query }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const router = useRouter();
    const isDM = process.env.DM === 'GB';
    const key = "GB-LNWEg577HlaPisGHdC5b2MZs9OTrzJQFGsvfhOUmt5SBdjpxwca8sOl0bscouJBB";
    const data = {
        liveKitUrl: query.liveKitUrl || null,
        token: query.token || null,
        e2ee_key: query.e2ee_key || null,
        id: query.id || null,
        conversation_id: query.conversation_id || null,
        api_key: query.api_key || null,
        session_id: query.session_id || null
    };
    useEffect(() => {
        if (data.liveKitUrl) {
            router.push(`/room/?liveKitUrl=${data.liveKitUrl}&token=${data.token}&session_id=${data.session_id}&api_key=${key}#${encodePassphrase(data.e2ee_key)}`);
        }
    }, []);
    if (data.liveKitUrl) {
        return <div></div>;
    }
    return (<>
      <main className={styles.main} data-lk-theme="default">
        <div className="header" style={{ margin: "16px" }}>
          {isDM ? (<img src="/images/logo.svg" alt="LiveKit Meet" width="360" height="45"/>) : (<div style={{ 'fontSize': '36px', 'fontWeight': 700 }}>Neuwork Voice</div>)}
        </div>
        {isDM ? <ConnectionTab_gpt label="Custom"/> : <ConnectionTab_neuwork label="Custom"/>}
      </main>
    </>);
};
export default Home;

    async function __Next_Translate__getServerSideProps__191bc16a426__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__191bc16a426__ as getServerSideProps }
  